
import Vue from 'vue'
import { ITranslations } from '../store/state'

interface LogotypeComputed {
	logotypeHeaderMobile: string
	logotypeHeaderDesktop: string
	isStartPage: boolean
	startPageURI: string
	translations: ITranslations
}

export default Vue.extend<{}, {}, LogotypeComputed, {}>({
	name: 'Logotype',
	props: {
		isMobile: false,
	},
	computed: {
		logotypeHeaderMobile() {
			return this.$store.getters.logotypeHeaderMobile;
		},
		logotypeHeaderDesktop() {
			return this.$store.getters.logotypeHeaderDesktop;
		},
		isStartPage() {
			return this.$store.getters.isStartPage;
		},
		startPageURI() {
			return this.$store.getters.startPageURI;
		},
		translations() {
			return this.$store.getters.translations;
		}
	}
})
