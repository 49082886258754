import { Getter } from "vuex";
import { ILogotypeData, IState } from './state';

const logotypeData: Getter<IState, IState> = (state) => state.logotypeData;
const links: Getter<IState, IState> = (state) => state.links;
const searchOpen: Getter<IState, IState> = (state) => state.searchOpen;
const menuItems: Getter<IState, IState> = (state) => state.menu.mainItems;
const menuOpen: Getter<IState, IState> = (state) => state.menu.isOpen;
const isMobile: Getter<IState, IState> = (state) => state.isMobile;
const suggestions: Getter<IState, IState> = (state) => state.suggestions;
const searchPageURI: Getter<IState, IState> = (state) => state.endpoints.searchPageURI;
const activeMainItem: Getter<IState, IState> = (state) => {
	const activeItem = state.menu.mainItems.find(mi => mi.isExpanded);
	return activeItem;
}
const logotypeHeaderMobile: Getter<IState, IState> = (state) => {
	if(state.isEnglish) {
		const imageData = state.logotypeData.find((i: ILogotypeData) => i.name === 'logga_ONH-centrerad-vit_eng.svg');
		if(imageData) return imageData.imageURI;
	} else {
		const imageData = state.logotypeData.find((i: ILogotypeData) => i.name === 'logga_ONH-centrerad-vit.svg');
		if(imageData) return imageData.imageURI;
	}
}
const logotypeHeaderDesktop: Getter<IState, IState> = (state) => {
	if(state.isEnglish) {
		const imageData = state.logotypeData.find((i: ILogotypeData) => i.name === 'logga_ONH-liggande-vit_eng.svg');
		if(imageData) return imageData.imageURI;
	} else {
		const imageData = state.logotypeData.find((i: ILogotypeData) => i.name === 'logga_ONH-liggande-vit.svg');
		if(imageData) return imageData.imageURI;
	}
}
const translations: Getter<IState, IState> = (state) => state.translations;
const isStartPage: Getter<IState, IState> = (state) => state.isStartPage;
const startPageURI: Getter<IState, IState> = (state) => state.startPageURI;
const listen: Getter<IState, IState> = (state) => state.listen
const sitename: Getter<IState, IState> = (state) => state.sitename
const showButtons: Getter<IState, IState> = (state) => state.showButtons

export default {
	logotypeData,
	links,
	searchOpen,
	isMobile,
	logotypeHeaderMobile,
	logotypeHeaderDesktop,
	menuOpen,
	menuItems,
	activeMainItem,
	suggestions,
	searchPageURI,
	translations,
	isStartPage,
	startPageURI,
	listen,
	sitename,
	showButtons
}
