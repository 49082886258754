
import Vue from 'vue'
import Logotype from './components/Logotype.vue'
import Links from './components/Links.vue'
import SearchButton from './components/SearchButton.vue'
import Search from './components/Search.vue'
import MenuButton from './components/MenuButton.vue'
import Menu from './components/Menu.vue'
import { resize } from './store/actions'

import { FocusTrap } from 'focus-trap-vue'

Vue.component('FocusTrap', FocusTrap);
// Interfaces for component typechecking!
interface AppData {
	isTablet: boolean
}

interface AppMethods {

}

interface AppComputed {
	searchOpen: boolean
	isMobile: boolean
	menuOpen: boolean
	hasSuggestions: boolean
	sitename: string
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	data() {
		return {
			isTablet: window.innerWidth <= 1025
		}
	},
	computed: {
		searchOpen(){
			return this.$store.getters.searchOpen;
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		menuOpen() {
			return this.$store.getters.menuOpen;
		},
		hasSuggestions() {
			return this.$store.getters.suggestions.length > 0;
		},
		sitename() {
			return this.$store.getters.suggestions.sitename;
		}
	},
	created() {
		this.$store.dispatch(resize(window.innerWidth))

		addEventListener('resize', () => {
			this.$store.dispatch(resize(window.innerWidth))
		})
	},
	
	components: {
		Logotype,
		Links,
		SearchButton,
		Search,
		MenuButton,
		Menu
	}
})
