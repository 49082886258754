import { IGetChildrenPayload } from '@/store/actions'
import { IMenu, IMenuItem } from '@/store/state'
import axios from 'axios'
import qs from 'query-string'

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Accept': 'application/json',
}


export const getMenu = (getMenuURL: string): Promise<IMenuItem[]> => {
    const url = getMenuURL;
    return axios.get(url, { headers })
        .then((response:any) => {

            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IMenuItem[]
        })
}

export const getMenuItems = (searchURL: string, params: IGetChildrenPayload): Promise<IMenuItem[]> => {
    let url = `${searchURL}&${qs.stringify({
        id: params.id,
        level: params.level
    })}`
    return axios.get(url, { headers })
        .then((response:any) => {
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IMenuItem[]
        })
}
export const getSuggestions = (searchURL: string, param: string): Promise<string[]> => {
    let url = `${searchURL}&${qs.stringify({
        query: param
    })}`
    return axios.get(url, { headers })
        .then((response:any) => {
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as string[]
        })
}